import axios from 'axios'

export default {
    data() {
        return {
            title: "传贝支付开放文档",
            project: {}, // 项目详情
            data: [], // 接口数据
            menuType: 'Home'
        }
    },
    created() {
        let that = this
        if (that.$route.params.type) {
            that.menuType = that.$route.params.type
        }
    },
    mounted() {
        let that = this
        axios.get('/api/api_project.json').then((res) => {
            that.project = res.data
        })

        function getSortFun(order, sortBy) {
            const ordAlpha = (order === 'asc') ? '>' : '<';
            return new Function('a', 'b', 'return a.' + sortBy + ordAlpha + 'b.' + sortBy + '?1:-1');
        }

        axios.get('/api/api.json').then((api) => {
            axios.get('/api/api_data.json').then((res_data) => {
                for (var i in api.data) {
                    if (api.data[i].value === 'pay') {
                        // 处理排序
                        var list = res_data.data
                        list.sort(getSortFun('asc', 'title'))
                        
                        for (var d in list) {
                            var t = list[d].title
                            list[d].title = t ? t.split(':')[1] : ''
                        }
                        
                        api.data[i].api = list
                        break;
                    }
                }
                that.data = api.data
            })
            axios.get('/api/register_data.json').then((res_data2) => {
                for (var i in api.data) {
                    if (api.data[i].value === 'register') {
                        // 处理排序
                        var list = res_data2.data
                        list.sort(getSortFun('asc', 'title'))
                        
                        for (var d in list) {
                            var t = list[d].title
                            list[d].title = t ? t.split(':')[1] : ''
                        }
                        
                        api.data[i].api = list
                        break;
                    }

                
                }
                that.data = api.data
            })
        })
    },
    methods: {
        clickMenu(type) {
            let that = this
            that.menuType = type
            that.$router.push({name: 'api', params: {type: type}})
        },

        isActive(type) {
            if (type === this.menuType) {
                return 'is-active'
            }
        }
    }
}
